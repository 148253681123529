import React from 'react';

function Indeed(p) {
  return (
    <div>
      fdsfad {p.query} {p.site}{' '}
    </div>
  );
}

export default Indeed;
